export enum UserType {
  CONSTRUCTION = 'CONSTRUCTION',
  MEDICAL = 'MEDICAL'
}

export const tabsConfig: Record<UserType, { value: string; isDefault: boolean; label: string; labelMobile: string; searchFilter: string}[]> = {
  [UserType.CONSTRUCTION]: [
    { value: 'projects', isDefault: true, label: 'Projects', labelMobile:  'Projects', searchFilter: 'tab:projects ' },
    { value: 'reports', isDefault: false, label: 'Reports', labelMobile:  'Reports', searchFilter: 'tab:reports ' },
    { value: 'site-inspection', isDefault: false, label: 'Site Inspections', labelMobile:  'Site', searchFilter: 'tab:inspections '},
    { value: 'observations', isDefault: false, label: 'Observations', labelMobile:  'Observations', searchFilter: 'tab:observations '},
    { value: 'media', isDefault: false, label: 'Files', labelMobile:  'Files', searchFilter: 'tab:files '}
  ],
  [UserType.MEDICAL]: [
    { value: 'patients', isDefault: true, label: 'Patients', labelMobile:  'Patients', searchFilter: 'tab:patients ' },
    { value: 'letters', isDefault: false, label: 'Letters', labelMobile:  'Letters', searchFilter: 'tab:letters ' },
    { value: 'consults', isDefault: false, label: 'Consults', labelMobile:  'Consults', searchFilter: 'tab:consults ' },
    { value: 'observations', isDefault: false, label: 'Observations', labelMobile:  'Obvs', searchFilter: 'tab:observations ' },
    { value: 'media', isDefault: false, label: 'Files', labelMobile:  'Files', searchFilter: 'tab:files ' }
  ]
};
