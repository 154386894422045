import { useOutletContext, useParams, Navigate } from 'react-router-dom';
import { useRecordingStore } from '@/store/recordingStore';
import { useEffect, useState } from 'react';
import { DetailsOutletContext } from '@/layouts/LayoutDetails';
import { Button } from '@/components/ui/button';
import { RefreshCcw } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Card, CardHeader, CardContent, CardTitle } from '@/components/ui/card';
import { ExploreOutletContext } from '@/layouts/Layout';
import { AudioPlayer } from "@/components/Audio";

export function VoiceDetails() {
  const [isRegenerating, setIsRegenerating] = useState(false);
  const { regenerateRecording } = useRecordingStore();

  const params = useParams();
  if (isNaN(Number(params.id))) return <Navigate to="/explore" />;
  const voice = useRecordingStore((state) => state.recordings.find((m) => m.id === Number(params.id)));
  if (!voice) return <Navigate to="/explore" />;

  const { setScreen } = useOutletContext<ExploreOutletContext>();

  useEffect(() => {
    setScreen({ screen: 'voice', id: Number(params.id) });
  }, [params.id, setScreen]);

  const { setParentTitle: setTitle, setCreatedAt, setActionButtons } = useOutletContext<DetailsOutletContext>();

  const [isEditingTranscript, setIsEditingTranscript] = useState(false);
  const [isEditingSummary, setIsEditingSummary] = useState(false);
  const [transcript, setTranscript] = useState(voice.transcript || '');
  const [summary, setSummary] = useState(voice.processedTranscript || '');

  useEffect(() => {
    setTitle('Recording');
    setTranscript(voice.transcript?.replace(/[\n\t]/g, '').trim() || '');
    setSummary(voice.processedTranscript?.replace(/[\n\t]/g, '').trim() || '');
    setCreatedAt(new Date(voice.uploaded_at));
  }, [voice]);

  const regenerateTranscriptAndSummary = async () => {
    setIsRegenerating(true);
    await regenerateRecording(voice.id);
    setIsRegenerating(false);
  };

  useEffect(() => {
    setActionButtons(
      <Button variant={'secondary'} onClick={regenerateTranscriptAndSummary} disabled={isRegenerating}>
        <RefreshCcw className={cn('w-4 h-4 mr-2', isRegenerating && 'animate-spin')} />
        Regenerate
      </Button>
    );

    return () => setActionButtons(null);
  }, [isRegenerating]);

  return (
    <div className="flex flex-col gap-4">
      <AudioPlayer src={`/api/files/voice/${voice.uri}`} />
      {voice.transcript ? (
        <div className="w-full flex flex-col gap-4">
          <Card>
            <CardHeader>
              <CardTitle>Transcript</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="rounded-lg" onClick={() => setIsEditingTranscript(true)}>
                {isEditingTranscript ? (
                  <textarea
                    className="w-full h-full focus:outline-none"
                    value={transcript}
                    onChange={(e) => setTranscript(e.target.value)}
                    onBlur={() => {
                      setIsEditingTranscript(false);
                      // TODO: Save changes to store/backend
                    }}
                    autoFocus
                  />
                ) : transcript ? (
                  <p className="text-muted-foreground text-sm">{transcript}</p>
                ) : (
                  <p className="text-muted-foreground text-sm">Click to add transcript</p>
                )}
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Summary</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="rounded-lg" onClick={() => setIsEditingSummary(true)}>
                {isEditingSummary ? (
                  <textarea
                    className="w-full h-full focus:outline-none"
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                    onBlur={() => {
                      setIsEditingSummary(false);
                      // TODO: Save changes to store/backend
                    }}
                    autoFocus
                  />
                ) : summary ? (
                  <p className="text-muted-foreground text-sm">{summary}</p>
                ) : (
                  <p className="text-muted-foreground text-sm">Click to add summary</p>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      ) : (
        <p className="italic text-muted-foreground text-sm">No transcript found</p>
      )}
    </div>
  );
}
