import { create } from 'zustand';
import { TabConfig } from '@/types/display'
import { DISPLAY_PATH_REGEX } from '@/config/domainConfig'


interface Filters {
  tab?: string;
  include?: string;
  exclude?: string;
  after?: string;
  before?: string;
}

interface SearchState {
  query: string;
  inputValue: string;
  filters: Filters;
  allTabFilters: string[],
  layoutMode: string;
  initialiseSearch: (defaultTab: TabConfig | null) => void;
  buildQueryString: (filters: Filters) => string;
  setQuery: (newQuery: string) => void;
  setInputValue: (newInputValue: string, navigate: (path: string) => void) => void;
  resetInputValue: (navigate: (path: string) => void) => void;
  clearInputValue: (navigate: (path: string) => void) => void;
  setFilters: (newFilters: Filters) => void;
  setLayoutMode: (path?: string) => void;
  getAllTabFilters: () => Promise<string[]>;
  isLayoutGlobal: () => boolean;
  isLayoutTabsSearching: () => boolean;
  isLayoutTabs: () => boolean;
  isLayoutDisplay: () => boolean;
  isCurrentTab: (searchFilter: string) => boolean;
}

const navigateExplore = '/explore'

export const useSearchStore = create<SearchState>((set, get) => {

  const updateLayoutMode = async () => {
    const filters = get().filters;
    const allTabFilters = await get().getAllTabFilters();
  
    if (allTabFilters.includes(filters.tab ?? '')) {
      set({ layoutMode: 'tabs' });
    } else if (filters.tab) {
      set({ layoutMode: 'tabsSearching' });
    } else {
      set({ layoutMode: 'global' });
    }
  };  

  return {
    query: '',
    inputValue: '',
    filters: {},
    allTabFilters: [],
    layoutMode: 'global',

    getAllTabFilters: async () => {
      const { useAuthStore } = await import('./authStore');
      const authStoreState = useAuthStore.getState();
      const allTabs = authStoreState.userTabs
        .map((tab: TabConfig) => tab.searchFilter.replace(/^tab:/, '').trim())
        .filter((filter) => filter.length > 0);
    
      set({ allTabFilters: allTabs });
      return allTabs;
    },

    initialiseSearch: async () => {

      const { useAuthStore } = await import('./authStore');
      const authStore = useAuthStore.getState();
    
      // Wait until userInitialised is true
      while (!authStore.userInitialised) {
        await new Promise((resolve) => setTimeout(resolve, 50));
      }
    
      const defaultTab = authStore.defaultTab;

      if (defaultTab) {
        set({
          query: defaultTab.searchFilter || '',
          inputValue: defaultTab.searchFilter || '',
          filters: { tab: defaultTab.searchFilter.replace(/^tab:/, '').trim() || '' },
        });
      } else {
        console.warn('No defaultTab found during search initialization');
      }
    },
    
    resetInputValue: async (navigate: (path: string) => void) => {
      const { useAuthStore } = await import('./authStore');
      const authStore = useAuthStore.getState();
      const defaultTab = authStore.defaultTab;

      if (defaultTab) {
        set({ inputValue: defaultTab.searchFilter || '' });
      }
    
      const { layoutMode } = get();
      if (layoutMode === 'display') {
        navigate(navigateExplore);
      }
    },

    clearInputValue: async (navigate: (path: string) => void) => {
      set({ inputValue: '' });
      const { layoutMode } = get();
      if (layoutMode === 'display') {
        navigate(navigateExplore);
      }
    },

    buildQueryString: (filters: Filters) => {
      const queryParts: string[] = [];
      if (filters.tab) queryParts.push(`tab:${filters.tab}`);
      if (filters.include) queryParts.push(filters.include);
      if (filters.exclude) queryParts.push(`-${filters.exclude}`);
      if (filters.after) queryParts.push(`after:${filters.after}`);
      if (filters.before) queryParts.push(`before:${filters.before}`);
      return queryParts.join(' ').trim();
    },

    setQuery: (newQuery: string) => {
      const newFilters: Filters = {};

      const typeMatch = newQuery.match(/tab:(\S*)/);
      const afterMatch = newQuery.match(/after:(\S*)/);
      const beforeMatch = newQuery.match(/before:(\S*)/);
      const excludeMatch = newQuery.match(/-(\S*)/);

      if (typeMatch) newFilters.tab = typeMatch[1] || '';
      if (afterMatch) newFilters.after = afterMatch[1] || '';
      if (beforeMatch) newFilters.before = beforeMatch[1] || '';
      if (excludeMatch) newFilters.exclude = excludeMatch[1] || '';

      const strippedQuery = newQuery
        .replace(/tab:\S*/g, '')
        .replace(/after:\S*/g, '')
        .replace(/before:\S*/g, '')
        .replace(/-\S*/g, '')
        .trim();

      if (strippedQuery) newFilters.include = strippedQuery;

      set({ query: newQuery, filters: newFilters });
      updateLayoutMode();
    },

    setInputValue: async (
      newInputValue: string,
      navigate: (path: string) => void
    ) => {
      set({ inputValue: newInputValue });
    
      const { useSelectedItemStore } = await import('./selectedItemStore');
      const selectedItemStore = useSelectedItemStore.getState();
    
      selectedItemStore.clearSelectedItems();
    
      const { layoutMode } = get();
      if (layoutMode === 'display') {
        navigate(navigateExplore);
      }
    },

    setFilters: (newFilters: Filters) => {
      set({ filters: newFilters });
      updateLayoutMode();
    },

    setLayoutMode: (path?: string) => {
      const filters = get().filters;
      const displayPathsRegex = DISPLAY_PATH_REGEX;

      if (path && displayPathsRegex.test(path)) {
        set({ layoutMode: 'display' });
      } else if (filters.tab) {
        set({ layoutMode: 'tabs' });
      } else {
        set({ layoutMode: 'global' });
      }
    },

    isLayoutGlobal: () => {
      return get().layoutMode === 'global';
    },
    
    isLayoutTabs: () => {
      return get().layoutMode === 'tabs';
    },
    
    isLayoutTabsSearching: () => {
      return get().layoutMode === 'tabsSearching';
    },

    isLayoutDisplay: () => {
      return get().layoutMode === 'display';
    },

    isCurrentTab: (searchFilter: string) => {
      const { query } = get();
      return query.includes(searchFilter.trim());
    },
  };
});
