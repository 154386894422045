import { useCallback, useEffect, useState } from 'react';
import { ItemType, useSelectedItemStore } from '@/store/selectedItemStore';
import { useAuthStore } from '@/store/authStore';
import { useProjectStore } from '@/store/projectStore.ts';
import { Navigate, useLocation, useOutletContext, useParams } from 'react-router-dom';
import { DetailsOutletContext } from '@/layouts/LayoutDetails';
import { Button } from '@/components/ui/button';
import { RefreshCcw } from 'lucide-react';
import { cn } from '@/lib/utils';
import { ListEntry } from '@/components/explore/ExploreEntry';
import { Textarea } from '@/components/ui/textarea';
import { ExploreOutletContext } from '@/layouts/Layout';
import { useNavigate } from 'react-router-dom';
import { titleCase } from '@/lib/utils';

export function ProjectDetails() {
  const { userDomainConfig } = useAuthStore();
  const navigate = useNavigate();
  const { state } = useLocation();
  const from = state?.from;
  const { toggleItem, isItemSelected, clearSelectedItems } = useSelectedItemStore();
  const { generateProjectSummary, updateDescription, updateTitle } = useProjectStore();

  const params = useParams();
  if (isNaN(Number(params.id))) return <Navigate to="/explore" />;
  const project = useProjectStore((state) => state.projects.find((p) => p.id === Number(params.id)));
  if (!project) return <Navigate to="/explore" />;

  const { setScreen } = useOutletContext<ExploreOutletContext>();

  useEffect(() => {
    setScreen({ screen: 'project', id: Number(params.id) });
  }, [params.id, setScreen]);

  const { setParentTitleCallback, setParentTitle, setCreatedAt, setActionButtons } =
    useOutletContext<DetailsOutletContext>();

  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(project.description || '');

  const updateTitleCallback = useCallback(
    (title: string) => {
      updateTitle(project.id, title);
    },
    [project.id]
  );

  useEffect(() => {
    setParentTitle(project.title || `${titleCase(userDomainConfig.objectNames.case)} #${project.id}`);
    setParentTitleCallback(() => updateTitleCallback);
    setDescription(project.description || '');
    setCreatedAt(new Date(project.createdAt));
  }, [project]);

  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout | null>(null);
  const debouncedSave = useCallback(
    (newDescription: string) => {
      if (saveTimeout) clearTimeout(saveTimeout);
      const timeout = setTimeout(() => {
        updateDescription(project.id, newDescription);
      }, 500);
      setSaveTimeout(timeout);
    },
    [project.id, updateDescription]
  );
  useEffect(() => {
    return () => {
      if (saveTimeout) clearTimeout(saveTimeout);
    };
  }, [saveTimeout]);

  const handleDescriptionChange = (newDescription: string) => {
    setDescription(newDescription);
    debouncedSave(newDescription);
  };

  const [isRegenerating, setIsRegenerating] = useState(false);
  const regenerate = async () => {
    setIsRegenerating(true);
    await generateProjectSummary(project.id);
    setIsRegenerating(false);
  };

  useEffect(() => {
    setActionButtons(
      <Button onClick={regenerate} variant="secondary" disabled={isRegenerating}>
        <RefreshCcw className={cn('w-4 h-4 mr-2', isRegenerating && 'animate-spin')} />
        Regenerate
      </Button>
    );

    return () => setActionButtons(null);
  }, [isRegenerating]);

  return (
    <div>
      <div className="flex flex-col gap-2">
        <h3 className="font-semibold">Description</h3>
        <div className="p-3" onClick={() => setIsEditing(true)}>
          {isEditing ? (
            <Textarea
              className="w-full h-full focus:outline-none"
              value={description}
              onChange={(e) => handleDescriptionChange(e.target.value)}
              onBlur={() => {
                setIsEditing(false);
                // TODO: Save changes to project store/backend
              }}
              autoFocus
            />
          ) : description ? (
            <p className="whitespace-pre-wrap">{description}</p>
          ) : (
            <p className="whitespace-pre-wrap text-muted-foreground">Click to add description</p>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <h3 className="font-semibold">Site Inspections</h3>
        <div className="flex flex-col gap-3 mb-36 border rounded-lg">
          {project.inspections.length === 0 ? (
            <p className="text-muted-foreground text-sm">No {userDomainConfig.objectNames.chronicle}s yet</p>
          ) : (
            <ul className="divide-y">
              {project.inspections.map((inspection) => (
                <li
                  key={inspection.id}
                  onClick={() => {
                    navigate(`/${userDomainConfig.urlChronicle}/${inspection.id}`, {
                      state: { from: [...(from || []), `${titleCase(userDomainConfig.objectNames.chronicle)}` ] }
                    });
                    clearSelectedItems();
                  }}
                  className="py-2 px-4 hover:bg-muted/40 transition-colors cursor-pointer"
                >
                  <ListEntry
                    title={inspection.title || `${titleCase(userDomainConfig.objectNames.chronicle)} #${inspection.id}`}
                    description={inspection.description}
                    date={new Date(inspection.createdAt)}
                    checked={isItemSelected({
                      remoteId: inspection.id,
                      itemType: userDomainConfig.objectNames.chronicle as ItemType
                    })}
                    onCheckedChange={() => toggleItem({ remoteId: inspection.id, itemType: userDomainConfig.objectNames.chronicle as ItemType })}
                    hasAudio={inspection.observations.some((obs) => obs.voices.length > 0)}
                    hasPhotos={inspection.observations.some((obs) => obs.photos.length > 0)}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
