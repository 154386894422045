import React, { useEffect } from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  Outlet
} from 'react-router-dom';
import { useAuthStore } from './store/authStore';
import Login from './components/Login';
import Layout from '@/layouts/Layout';
import LayoutHeader from '@/layouts/LayoutHeader';
import LayoutTabs from '@/layouts/LayoutTabs';
import LayoutDetails from './layouts/LayoutDetails';
import { SiteInspectionDetails } from './pages/explore/details/InspectionDetails';
import { ObservationDetails } from './pages/explore/details/ObservationDetails';
import { ProjectDetails } from './pages/explore/details/ProjectDetails';
import { PhotoDetails } from './pages/explore/details/PhotoDetails';
import { VoiceDetails } from './pages/explore/details/VoiceDetails';
import { ReportDetails } from './pages/explore/details/ReportDetails';
import { DOMAIN_CONFIGS } from '@/config/domainConfig';

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuthStore();
  if (loading) return <div>Loading...</div>;
  if (!user) return <Navigate to="/login" replace />;
  return <>{children}</>;
}

function AppLayout() {
  return <Outlet />;
}

function App() {
  const { checkAuth, user, loading } = useAuthStore();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) return <div>Loading...</div>;

  const domainKey = user?.user_type?.toLowerCase() || 'construction';
  const domainConfig = DOMAIN_CONFIGS[domainKey];

  const router = createBrowserRouter([
    {
      path: '/',
      element: <AppLayout />,
      children: [
        { path: 'login', element: <Login /> },
        {
          path: `/explore`,
          element: (
            <ProtectedRoute>
              <LayoutHeader />
            </ProtectedRoute>
          ),
          children: [
            {
              element: <Layout />,
              children: [{ index: true, element: <LayoutTabs /> }]
            }
          ]
        },
        {
          path: `/${domainConfig.urlPrefix}`,
          element: (
            <ProtectedRoute>
              <LayoutHeader />
            </ProtectedRoute>
          ),
          children: [
            {
              element: <Layout />,
              children: [
                {
                  element: <LayoutDetails />,
                  children: [
                    { path: `${domainConfig.objectNames.case}/:id`, element: <ProjectDetails /> },
                    { path: `${domainConfig.objectNames.artifact}/:id`, element: <ReportDetails /> },
                    { path: `${domainConfig.objectNames.chronicle}/:id`, element: <SiteInspectionDetails /> },
                    { path: `${domainConfig.objectNames.observation}/:id`, element: <ObservationDetails /> },
                    { path: `${domainConfig.objectNames.recording}/:id`, element: <VoiceDetails /> },
                    { path: `${domainConfig.objectNames.photo}/:id`, element: <PhotoDetails /> }
                  ]
                }
              ]
            }
          ]
        },
        { path: '/', element: <Navigate to={`/explore`} replace /> },
        { path: '*', element: <Navigate to={`/explore`} replace /> }
      ]
    },
  ],
  {
    future: {
      v7_startTransition: true
    }
  }
);

  return <RouterProvider router={router} />;
}

export default App;
