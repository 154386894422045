import { create } from 'zustand';
import { ItemType } from './selectedItemStore'
import { DomainConfig } from '@/config/domainConfig';
import { titleCase } from '@/lib/utils';


interface SearchResult {
  uuid: string;
  id: number;
  uri: string;
  itemType: ItemType;
  type: string;
  title: string;
  description: string;
  createdAt: string;
  sortDate: string;
}

interface SearchResultStore {
  results: SearchResult[];
  paginatedResults: SearchResult[];
  pagination: {
    page: number;
    resultsPerPage: number;
    totalPages: number;
  };
  currentIndex: number;
  setFilteredResults: (data: {
    projects: any[];
    reports: any[];
    inspections: any[];
    observations: any[];
    recordings: any[];
    photos: any[];
  }) => void;
  setPage: (page: number) => void;
  setCurrentItem: (item: SearchResult) => void; 
  navigateToItem: (direction: 'prev' | 'next') => void;
  getCurrentItem: () => SearchResult | null;
  getPreviousItem: () => SearchResult | null;
  getNextItem: () => SearchResult | null;
}

export const useSearchResult = create<SearchResultStore>((set, get) => {

  const getDomainConfig = async (): Promise<DomainConfig> => {
    const { useAuthStore } = await import('./authStore');
    const authStore = useAuthStore.getState();

    // Wait until userInitialised is true
    while (!authStore.userInitialised) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }

    return authStore.userDomainConfig;
  };

  return {
    results: [],
    paginatedResults: [],
    pagination: {
      page: 1,
      resultsPerPage: 10,
      totalPages: 0,
    },
    currentIndex: 0,

    setFilteredResults: async (data) => {
      const { projects, reports, inspections, observations, recordings, photos } = data;
      const domainConfig = await getDomainConfig();
    
      const combinedResults: SearchResult[] = [
        ...projects.map((item) => ({
          uuid: `Case-${item.id}`,
          id: item.id,
          uri: `/${domainConfig.urlCase}/${item.id}`,
          itemType: domainConfig.objectNames.case as ItemType,
          type: `${titleCase(domainConfig.objectNames.case)}`,
          title: item.title || `${titleCase(domainConfig.objectNames.case)}`,
          description: item.description || 'No description available',
          createdAt: item.createdAt,
          sortDate: item.createdAt,
        })),
        ...reports.map((item) => ({
          uuid: `Artifact-${item.id}`,
          id: item.id,
          uri: `/${domainConfig.urlArtifact}/${item.id}`,
          itemType: domainConfig.objectNames.artifact as ItemType,
          type: `${titleCase(domainConfig.objectNames.artifact)}`,
          title: item.title || `${titleCase(domainConfig.objectNames.artifact)}`,
          description: item.description || 'No description available',
          createdAt: item.createdAt,
          sortDate: item.createdAt,
        })),
        ...inspections.map((item) => ({
          uuid: `Chronicle-${item.id}`,
          id: item.id,
          uri: `/${domainConfig.urlChronicle}/${item.id}`,
          itemType: domainConfig.objectNames.chronicle as ItemType,
          type: `${titleCase(domainConfig.objectNames.chronicle)}`,
          title: item.title || `${titleCase(domainConfig.objectNames.chronicle)}`,
          description: item.description || 'No description available',
          createdAt: item.createdAt,
          sortDate: item.createdAt,
        })),
        ...observations.map((item) => ({
          uuid: `Observation-${item.id}`,
          id: item.id,
          uri: `/${domainConfig.urlObservation}/${item.id}`,
          itemType: domainConfig.objectNames.observation as ItemType,
          type: `${titleCase(domainConfig.objectNames.observation)}`,
          title: item.title || `${titleCase(domainConfig.objectNames.observation)}`,
          description: item.description || 'No description available',
          createdAt: item.createdAt,
          sortDate: item.createdAt,
        })),
        ...recordings.map((item) => ({
          uuid: `Recording-${item.id}`,
          id: item.id,
          uri: `/${domainConfig.urlRecording}/${item.id}`,
          itemType: domainConfig.objectNames.recording as ItemType,
          type: `${titleCase(domainConfig.objectNames.recording)}`,
          title: item.title || `${titleCase(domainConfig.objectNames.recording)}`,
          description: item.processedTranscript || 'No description available',
          createdAt: item.uploaded_at,
          sortDate: item.uploaded_at,
        })),
        ...photos.map((item) => ({
          uuid: `Photo-${item.id}`,
          id: item.id,
          uri: `/${domainConfig.urlPhoto}/${item.id}`,
          itemType: domainConfig.objectNames.photo as ItemType,
          type: `${titleCase(domainConfig.objectNames.photo)}`,
          title: item.title || `${titleCase(domainConfig.objectNames.photo)}`,
          description: item.llm_summary || 'No description available',
          createdAt: item.uploaded_at,
          sortDate: item.uploaded_at,
        })),
      ];

      // Sort results by sortDate in descending order
      combinedResults.sort((a, b) => new Date(b.sortDate).getTime() - new Date(a.sortDate).getTime());

      const resultsPerPage = get().pagination.resultsPerPage;
      const totalPages = Math.ceil(combinedResults.length / resultsPerPage);

      set(() => ({
        results: combinedResults,
        pagination: {
          page: 1,
          resultsPerPage,
          totalPages
        },
        paginatedResults: combinedResults.slice(0, resultsPerPage),
      }));
    },

    setPage: (page) => {
      const { results, pagination } = get();
      const startIndex = (page - 1) * pagination.resultsPerPage;
      const endIndex = startIndex + pagination.resultsPerPage;

      set(() => ({
        pagination: {
          ...pagination,
          page,
        },
        paginatedResults: results.slice(startIndex, endIndex),
      }));
    },

    setCurrentItem: (item: SearchResult) => {
      const { results } = get();
      const index = results.indexOf(item);
      if (index !== -1) {
        set(() => ({
          currentIndex: index,
        }));
      }
    },

    navigateToItem: (direction) => {
      const { currentIndex, results } = get();
      const newIndex =
        direction === 'next'
          ? Math.min(currentIndex + 1, results.length - 1)
          : Math.max(currentIndex - 1, 0);

      set(() => ({ currentIndex: newIndex }));
    },

    getCurrentItem: () => {
      const { results, currentIndex } = get();
      return results[currentIndex] || null;
    },

    getPreviousItem: () => {
      const { results, currentIndex } = get();
      return results[currentIndex - 1] || null;
    },

    getNextItem: () => {
      const { results, currentIndex } = get();
      return results[currentIndex + 1] || null;
    }
  }
});
