import { Fragment, useEffect } from 'react';
import { useAuthStore } from '@/store/authStore.ts';
import { useOutletContext, Navigate } from 'react-router-dom';
import { ExploreOutletContext } from '@/layouts/Layout';
import { useObservationStore } from '@/store/observationStore';
import { RefreshCcw } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ExploreSearchResults } from '@/components/explore/ExploreSearchResults'
import { useRecordingStore } from '@/store/recordingStore';
import { usePhotoStore } from '@/store/photoStore';
import { Skeleton } from '@/components/ui/skeleton.tsx';
import { ExploreActions } from '../components/explore/ExploreActions';
import { ExploreNavTabs } from '../components/explore/ExploreNavTabs';

export default function LayoutTabs() {
  const { setScreen } = useOutletContext<ExploreOutletContext>();

  useEffect(() => {
    setScreen(null);
  }, [setScreen]);

  const { user } = useAuthStore();
  if (!user) return <Navigate to="/login" />;

  const { isLoading: obsLoading, error, retry } = useObservationStore();
  const { isLoading: recordingsLoading } = useRecordingStore();
  const { isLoading: photosLoading } = usePhotoStore(); 

  return (
    <Fragment>
      <ExploreActions />
      <ExploreNavTabs />
      {obsLoading || recordingsLoading || photosLoading ? (
        <Skeleton className="m-4 flex h-8" />
      ) : error ? (
        <div className="flex flex-col items-center mt-4 gap-4 w-full">
          <p className="text-destructive">{error}</p>
          <Button variant="outline" size="sm" onClick={() => retry()} className="flex items-center gap-2">
            <RefreshCcw className="h-4 w-4" />
            Retry
          </Button>
        </div>
      ) : (
        <ExploreSearchResults />
      )}
    </Fragment>
  );
}
