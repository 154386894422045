import { useCallback, useEffect, useState } from 'react';
import { useReportStore } from '@/store/reportStore.ts';
import { ItemType, useSelectedItemStore } from '@/store/selectedItemStore';
import { useAuthStore } from '@/store/authStore';
import { Navigate, useLocation, useOutletContext, useParams } from 'react-router-dom';
import { DetailsOutletContext } from '@/layouts/LayoutDetails';
import { Button } from '@/components/ui/button';
import { RefreshCcw } from 'lucide-react';
import { cn } from '@/lib/utils';
import { ListEntry } from '@/components/explore/ExploreEntry';
import { Textarea } from '@/components/ui/textarea';
import { ExploreOutletContext } from '@/layouts/Layout';
import Markdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { titleCase } from '@/lib/utils';

export function ReportDetails() {
  const { userDomainConfig } = useAuthStore();
  const navigate = useNavigate();
  const { toggleItem, isItemSelected, clearSelectedItems } = useSelectedItemStore();
  const { state } = useLocation();
  const from = state?.from;

  const { regenerateReport, updateDescription, updateTitle } = useReportStore();

  const params = useParams();
  if (isNaN(Number(params.id))) return <Navigate to="/explore" />;
  const report = useReportStore((state) => state.reports.find((r) => r.id === Number(params.id)));
  if (!report) return <Navigate to="/explore" />;
  const { setScreen } = useOutletContext<ExploreOutletContext>();

  useEffect(() => {
    setScreen({ screen: 'project', id: Number(params.id) });
  }, [params.id, setScreen]);

  const { setParentTitleCallback, setParentTitle, setCreatedAt, setActionButtons } =
    useOutletContext<DetailsOutletContext>();

  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(report.description || '');

  const updateTitleCallback = useCallback(
    (title: string) => {
      updateTitle(report.id, title);
    },
    [report.id]
  );

  useEffect(() => {
    setParentTitle(report.title || `Report #${report.id}`);
    setParentTitleCallback(() => updateTitleCallback);
    setDescription(report.description || '');
    setCreatedAt(new Date(report.createdAt));
  }, [report]);

  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout | null>(null);
  const debouncedSave = useCallback(
    (newDescription: string) => {
      if (saveTimeout) clearTimeout(saveTimeout);
      const timeout = setTimeout(() => {
        updateDescription(report.id, newDescription);
      }, 500);
      setSaveTimeout(timeout);
    },
    [report.id, updateDescription]
  );
  useEffect(() => {
    return () => {
      if (saveTimeout) clearTimeout(saveTimeout);
    };
  }, [saveTimeout]);

  const handleDescriptionChange = (newDescription: string) => {
    setDescription(newDescription);
    debouncedSave(newDescription);
  };

  const [isRegenerating, setIsRegenerating] = useState(false);
  const regenerate = async () => {
    setIsRegenerating(true);
    await regenerateReport(report.id);
    setIsRegenerating(false);
  };

  useEffect(() => {
    setActionButtons(
      <Button onClick={regenerate} variant="secondary" disabled={isRegenerating}>
        <RefreshCcw className={cn('w-4 h-4 mr-2', isRegenerating && 'animate-spin')} />
        Regenerate
      </Button>
    );

    return () => setActionButtons(null);
  }, [isRegenerating]);

  return (
    <div>
      <div className="flex flex-col gap-2">
        <div className="p-3 pt-0 w-full" onClick={() => setIsEditing(true)}>
          {isEditing ? (
            <Textarea
              className="w-full h-full focus:outline-none"
              value={description}
              onChange={(e) => handleDescriptionChange(e.target.value)}
              onBlur={() => setIsEditing(false)}
              autoFocus
            />
          ) : description ? (
            <Markdown
              className="prose w-full max-w-none"
              components={{
                img: ({ node, ...props }) => (
                  <img
                    {...props}
                    className="cursor-pointer max-h-[200px] inline m-0"
                    alt={props.alt}
                    onClick={(e) => e.stopPropagation()}
                  />
                ),
              }}
            >
              {description}
            </Markdown>
          ) : (
            <p className="whitespace-pre-wrap text-muted-foreground">Click to add description</p>
          )}
        </div>
      </div>
      {report.observations.length > 0 && (
        <div className="flex flex-col gap-2">
          <h3 className="font-semibold">Observations</h3>
          <div className="flex flex-col gap-3 mb-36 border rounded-lg">
            <ul className="divide-y">
              {report.observations.map((observation) => (
                <li
                  key={observation.id}
                  onClick={() =>
                    navigate(`/${userDomainConfig.urlObservation}/${observation.id}`, {
                      state: { from: [...(from || []), 'Observation'] }
                    })
                  }
                  className="py-2 px-4 hover:bg-muted/40 transition-colors cursor-pointer"
                >
                  <ListEntry
                    title={observation.title || `Observation #${observation.id}`}
                    description={observation.description}
                    date={new Date(observation.createdAt)}
                    checked={false}
                    onCheckedChange={() => {}}
                    hasAudio={observation.voices.length > 0}
                    hasPhotos={observation.photos.length > 0}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {report.inspections.length > 0 && (
        <div className="flex flex-col gap-2">
          <h3 className="font-semibold">Site Inspections</h3>
          <div className="flex flex-col gap-3 mb-36 border rounded-lg">
            <ul className="divide-y">
              {report.inspections.map((inspection) => (
                <li
                  key={inspection.id}
                  onClick={() => {
                    navigate(`/${userDomainConfig.urlChronicle}/${inspection.id}`, {
                      state: { from: [...(from || []), `${titleCase(userDomainConfig.objectNames.chronicle)}`] }
                    });
                    clearSelectedItems();
                  }}
                  className="py-2 px-4 hover:bg-muted/40 transition-colors cursor-pointer"
                >
                  <ListEntry
                    title={inspection.title || `${titleCase(userDomainConfig.objectNames.chronicle)} #${inspection.id}`}
                    description={inspection.description}
                    date={new Date(inspection.createdAt)}
                    checked={isItemSelected({
                      remoteId: inspection.id,
                      itemType: userDomainConfig.objectNames.chronicle as ItemType
                    })}
                    onCheckedChange={() => toggleItem({ remoteId: inspection.id, itemType: userDomainConfig.objectNames.chronicle as ItemType })}
                    hasAudio={inspection.observations.some((obs) => obs.voices.length > 0)}
                    hasPhotos={inspection.observations.some((obs) => obs.photos.length > 0)}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
