export type DomainObjectNames = {
    case: string;
    artifact: string;
    chronicle: string;
    observation: string;
    recording: string;
    photo: string;
};
  
export type DomainConfig = {
    name: string;
    urlPrefix: string;
    objectNames: DomainObjectNames;
    urlCase: string;
    urlArtifact: string;
    urlChronicle: string;
    urlObservation: string;
    urlRecording: string;
    urlPhoto: string;
};

export const DISPLAY_PATH_REGEX = /^\/medical|construction\/(project|patient|report|letter|inspection|observation|consult|recording|voice|photo)\/\d+$/;
  
export const DOMAIN_CONFIGS: Record<string, DomainConfig> = {
    construction: {
        name: 'Construction',
        urlPrefix: 'construction',
        objectNames: {
            case: 'project',
            artifact: 'report',
            chronicle: 'inspection',
            observation: 'observation',
            recording: 'recording',
            photo: 'photo'
        },
        urlCase: 'construction/project',
        urlArtifact: 'construction/report',
        urlChronicle: 'construction/inspection',
        urlObservation: 'construction/observation',
        urlRecording: 'construction/recording',
        urlPhoto: 'construction/photo'
    },
    medical: {
        name: 'Medical',
        urlPrefix: 'medical',
        objectNames: {
            case: 'patient',
            artifact: 'letter',
            chronicle: 'consult',
            observation: 'observation',
            recording: 'recording',
            photo: 'photo'
        },
        urlCase: 'medical/patient',
        urlArtifact: 'medical/letter',
        urlChronicle: 'medical/consult',
        urlObservation: 'medical/observation',
        urlRecording: 'medical/recording',
        urlPhoto: 'medical/photo'
    }
}
  