import { useObservationStore } from '@/store/observationStore';
import { ChevronLeftIcon, ChevronRightIcon, ClipboardPlus, Loader2, RefreshCwIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useRecordingStore } from '@/store/recordingStore';
import { usePhotoStore } from '@/store/photoStore';
import { Checkbox } from '../ui/checkbox';
import { useSelectedItemStore } from '@/store/selectedItemStore.ts';
import { cn } from '@/lib/utils.ts';
import { useAuthStore } from '@/store/authStore';
import { useReportStore } from '@/store/reportStore';
import { useSearchStore } from '@/store/searchStore';
import { useSearchResult } from '@/store/searchResultStore';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../ui/dialog';

export function ExploreActions() {
  const { isLoading: obsLoading } = useObservationStore();
  const { isLoading: recordingsLoading } = useRecordingStore();
  const { isLoading: photosLoading } = usePhotoStore(); 
  const [ isGeneratingReport, setIsGeneratingReport ] = useState(false);
  const { generateReport } = useReportStore();
  const { selectedItems, clearSelectedItems } = useSelectedItemStore();
  const { isLayoutDisplay } = useSearchStore();
  const navigate = useNavigate();
  const [disabledGenerateReportDialogOpen, setDisabledGenerateReportDialogOpen] = useState(false);

  const { pagination, results, setPage, setCurrentItem, getCurrentItem, getPreviousItem, getNextItem } = useSearchResult();
  const currentItem = getCurrentItem();
  const previousItem = getPreviousItem();
  const nextItem = getNextItem();
  const totalResults = results.length;

  const handlePageChange = (direction: 'next' | 'prev') => {
    if (!pagination) return;
    const newPage = direction === 'next' ? pagination.page + 1 : pagination.page - 1;
    if (newPage < 1 || newPage > pagination.totalPages) return;
    setPage(newPage);
  };
  
  const renderPaginationListControls = () => {
    const { page, resultsPerPage, totalPages } = pagination;
    const totalResults = results.length; // Total results in the store
  
    // Recalculate start and end results
    const startResult = totalResults === 0 ? 0 : (page - 1) * resultsPerPage + 1;
    const endResult = totalResults === 0 ? 0 : Math.min(startResult + resultsPerPage - 1, totalResults);
  
    return (
      <div className="flex items-center gap-4">
        <div className="text-sm text-muted-foreground">
          <span className="hidden md:inline">Results </span>
          {`${startResult}-${endResult} of ${totalResults}`}
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8"
            onClick={() => handlePageChange('prev')}
            disabled={page === 1 || totalResults === 0}
          >
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8"
            onClick={() => handlePageChange('next')}
            disabled={page === totalPages || totalResults === 0}
          >
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
    );
  };

  const renderPaginationBrowseControls = () => {
    if (totalResults === 0) {
      return <p className="text-sm text-muted-foreground">No items to browse.</p>;
    }
  
    return (
      <div className="flex items-center gap-4">
        <div className="text-sm text-muted-foreground">
          {`${results.indexOf(currentItem!) + 1} of ${totalResults}`}
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8"
            onClick={() => {
              if (previousItem) {
                setCurrentItem(previousItem);
                navigate(previousItem.uri, { state: { from: [previousItem.type] }});
              }
            }}
            disabled={!previousItem}
          >
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8"
            onClick={() => {
              if (nextItem) {
                setCurrentItem(nextItem);
                navigate(nextItem.uri, { state: { from: [nextItem.type] }});
              }
            }}
            disabled={!nextItem}
          >
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
    );
  };

  const { userDomainConfig } = useAuthStore();
  
  return (
    <>
      <div className="bg-stone-100 px-4 pt-4 pb-2">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1">
              <Checkbox
                className="ml-1 mr-2"
                checked={selectedItems.length > 0}
                onCheckedChange={() => clearSelectedItems()}
              />
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8"
                disabled={obsLoading || recordingsLoading || photosLoading}
              >
                <RefreshCwIcon className={cn('h-4 w-4', obsLoading || recordingsLoading || photosLoading ? 'animate-spin' : '')} />
              </Button>

              <Button
                variant="ghost"
                size="icon"
                className={cn(
                  'h-8 w-8',
                  selectedItems.length === 0
                    ? 'opacity-50'
                    : '',
                  isGeneratingReport ? 'cursor-not-allowed opacity-50' : ''
                )}
                onClick={async () => {
                  if (isGeneratingReport) return;
                  if (
                    selectedItems.length === 0
                  ) {
                    setDisabledGenerateReportDialogOpen(true);
                    return;
                  }
                  setIsGeneratingReport(true);
                  const report = await generateReport();
                  setIsGeneratingReport(false);
                  if (report) navigate(`/${userDomainConfig.urlArtifact}/${report.id}`, { state: { from: ['Report'] } });
                }}
              >
                {isGeneratingReport ? (
                  <Loader2 className="h-4 w-4 animate-spin" />
                ) : (
                  <ClipboardPlus className="h-4 w-4" />
                )}
              </Button>
              {selectedItems.length > 0 && (
                <p className={'text-muted-foreground text-sm italic'}>
                  {selectedItems.length}
                  <span className="hidden sm:inline"> selected</span>
                </p>
              )}
            </div>
            {isLayoutDisplay() ? (
              renderPaginationBrowseControls()
            ) : (
              renderPaginationListControls()
            )}
          </div>
        </div>
      </div>
      <Dialog open={disabledGenerateReportDialogOpen} onOpenChange={setDisabledGenerateReportDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Cannot generate report</DialogTitle>
            <DialogDescription>
              <ul className="list-disc list-inside text-left">
                <li>
                  You must be in <strong>Site Inspection</strong> or <strong>Observations</strong> tab to generate
                  report.
                </li>
                <li>You must select at least one item to generate a report.</li>
              </ul>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
}
