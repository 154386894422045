import { useEffect, useState, useCallback } from 'react';
import { useInspectionStore } from '@/store/inspectionStore';
import { useSelectedItemStore } from '@/store/selectedItemStore';
import { useAuthStore } from '@/store/authStore';
import { useParams, useOutletContext, Navigate, useLocation } from 'react-router-dom';
import { DetailsOutletContext } from '@/layouts/LayoutDetails';
import { Button } from '@/components/ui/button';
import { Link, Plus, RefreshCcw } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useObservationStore } from '@/store/observationStore';
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenuContent
} from '@/components/ui/dropdown-menu';
import { ListEntry } from '@/components/explore/ExploreEntry';
import { Textarea } from '@/components/ui/textarea';
import { ExploreOutletContext } from '@/layouts/Layout';
import { useNavigate } from 'react-router-dom';

export function SiteInspectionDetails() {
  const { userDomainConfig } = useAuthStore();
  const { inspections, generateSummary, updateTitle, updateDescription } = useInspectionStore();
  const { setIsCreating, setIsLinking } = useObservationStore();
  const { toggleItem, isItemSelected, clearSelectedItems } = useSelectedItemStore();
  const params = useParams();

  const navigate = useNavigate();
  const { state } = useLocation();
  const from = state?.from;

  const { setScreen } = useOutletContext<ExploreOutletContext>();

  useEffect(() => {
    setScreen({ screen: 'inspection', id: Number(params.id) });
  }, [params.id, setScreen]);

  if (isNaN(Number(params.id))) return <Navigate to="/explore" />;

  const inspection = inspections.find((i) => i.id === Number(params.id));

  if (!inspection) return <Navigate to="/explore" />;

  const { setParentTitle, setParentTitleCallback, setCreatedAt, setActionButtons } =
    useOutletContext<DetailsOutletContext>();

  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(inspection.description || '');

  const updateTitleCallback = useCallback(
    (title: string) => {
      updateTitle(inspection.id, title);
    },
    [inspection.id]
  );

  useEffect(() => {
    setParentTitle(inspection.title || `Site Inspection #${inspection.id}`);
    setParentTitleCallback(() => updateTitleCallback);
    setDescription(inspection.description || '');
    setCreatedAt(new Date(inspection.createdAt));
  }, [inspection]);

  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout | null>(null);

  const debouncedSave = useCallback(
    (newDescription: string) => {
      if (saveTimeout) clearTimeout(saveTimeout);
      const timeout = setTimeout(() => {
        updateDescription(inspection.id, newDescription);
      }, 500);
      setSaveTimeout(timeout);
    },
    [inspection.id, updateDescription]
  );
  useEffect(() => {
    return () => {
      if (saveTimeout) clearTimeout(saveTimeout);
    };
  }, [saveTimeout]);

  const handleDescriptionChange = (newDescription: string) => {
    setDescription(newDescription);
    debouncedSave(newDescription);
  };

  const [isRegenerating, setIsRegenerating] = useState(false);
  const regenerate = async () => {
    setIsRegenerating(true);
    await generateSummary(inspection.id);
    setIsRegenerating(false);
  };

  useEffect(() => {
    setActionButtons(
      <Button variant={'secondary'} onClick={regenerate} disabled={isRegenerating}>
        <RefreshCcw className={cn('w-4 h-4 mr-2', isRegenerating && 'animate-spin')} />
        Regenerate
      </Button>
    );

    return () => setActionButtons(null);
  }, [isRegenerating]);

  return (
    <div className="flex flex-col gap-2">
      <h3 className="font-semibold">Description</h3>
      <div className="p-3" onClick={() => setIsEditing(true)}>
        {isEditing ? (
          <Textarea
            className="w-full h-full focus:outline-none"
            value={description}
            onChange={(e) => handleDescriptionChange(e.target.value)}
            onBlur={() => setIsEditing(false)}
            autoFocus
          />
        ) : description ? (
          <p className="whitespace-pre-wrap">{description}</p>
        ) : (
          <p className="whitespace-pre-wrap text-muted-foreground">Click to add description</p>
        )}
      </div>

      <div>
        <div className="flex mb-3 items-center gap-4">
          <h3 className="font-semibold">Observations</h3>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant={'secondary'}>
                <Plus className="w-4 h-4 mr-2" />
                Add
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuGroup>
                <DropdownMenuItem onClick={() => setIsCreating(true, inspection.id)} className="cursor-pointer">
                  <Plus className="w-4 h-4 mr-2" />
                  Create new
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setIsLinking(true, inspection.id)} className="cursor-pointer">
                  <Link className="w-4 h-4 mr-2" />
                  Link existing
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="flex flex-col mb-36 border rounded-lg">
          {inspection.observations.length === 0 ? (
            <p className="text-muted-foreground text-sm">No observations yet</p>
          ) : (
            <ul className="divide-y">
              {inspection.observations.map((observation) => (
                <li
                  key={observation.id}
                  onClick={() => {
                    navigate(`/${userDomainConfig.urlObservation}/${observation.id}`, {
                      state: { from: [...(from || []), 'Observation'] }
                    });
                    clearSelectedItems();
                  }}
                  className="py-2 px-4 hover:bg-muted/40 transition-colors cursor-pointer"
                >
                  <ListEntry
                    title={observation.title || `Observation #${observation.id}`}
                    description={observation.description}
                    date={new Date(observation.createdAt)}
                    checked={isItemSelected({
                      remoteId: observation.id,
                      itemType: 'observation'
                    })}
                    onCheckedChange={() => toggleItem({ remoteId: observation.id, itemType: 'observation' })}
                    hasAudio={observation.voices.length > 0}
                    hasPhotos={observation.photos.length > 0}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
